import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import ForgotPassword from "./ForgotPassword";
import Privacy from "./Privacy";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Terms from "./Terms";

const App = () => {
    return (
            <Routes>
                <Route path={`/`} element={<Home />} />
                <Route path={`/sign-in`} element={<SignIn />} />
                <Route path={`/sign-up`} element={<SignUp />} />
                <Route path={`/privacy`} element={<Privacy />} />
                <Route path={`/forgot-password`} element={<ForgotPassword />} />
                <Route path={`/terms`} element={<Terms />} />
            </Routes>
    );
};

export default App;